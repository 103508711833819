
































import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class extends Vue {
  @Prop({}) open!: boolean
  @Prop({}) description!: string
  @Prop({}) loading!: boolean
  // resolve?: (value: any) => void

  // reject(val: boolean) {
  //   this.resolve && this.resolve(val)
  //   this.dialog = false
  // }

  // open() {
  //   this.dialog = true
  //   return new Promise((resolve) => (this.resolve = resolve))
  // }
}
